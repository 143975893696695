import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '@gravity-angular/layout';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent implements OnInit {

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly loadingService: LoadingService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.authorize();
  }

  authorize(): void {
    this.loadingService.showLoading(true);
    this.oidcSecurityService.checkAuth().subscribe(
      async authResults => {
        if (!authResults['isAuthenticated']) {
          this.loadingService.showLoading(false);
          this.oidcSecurityService.authorize();
        } else {
          this.loadingService.showLoading(false);
          localStorage.setItem('state', 'valid');

          return sessionStorage.getItem('route')
            ? this.router.navigate([sessionStorage.getItem('route')])
            : this.router.navigate(['/login']);
        }
      },
      error => {
        console.error(error);
        this.router.navigate(['/login']);
      }
    );
  }

}
