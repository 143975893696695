import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserPreference, SavedFilter } from '../models/user-preference.model';
import { UserPreferenceTypes } from './user-preference.type';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';

export interface UserPreferenceResponse {
  commitId: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {
  userPreference: UserPreference;
  commitID: string;

  constructor(private readonly httpClient: HttpClient) {}

  initUserPreference(userPreference: UserPreference, commitID: string): void {
    this.userPreference = userPreference;
    this.commitID = commitID;
  }

  getPreferenceByType(preference: UserPreferenceTypes): any {
    return this.userPreference[preference] || [];
  }

  getDashboardWidgets(): SavedFilter[] {
    return this.getPreferenceByType(UserPreferenceTypes.filters)
      .filter((w: SavedFilter) => {
        return w.isWidget;
      })
      .sort((a, b) => {
        return a.widgetIndex - b.widgetIndex;
      });
  }

  getUnselectedFilters(): SavedFilter[] {
    return this.getPreferenceByType(UserPreferenceTypes.filters).filter(
      (w: SavedFilter) => {
        return !w.isWidget && w.filter.relativeDateRange;
      }
    );
  }

  getSavedFilters(): SavedFilter[] {
    return this.getPreferenceByType(UserPreferenceTypes.filters);
  }

  deleteSavedFilter(title: string): Observable<UserPreferenceResponse> {
    const toBeDeletedFilter = this.userPreference.filters.find(
      (savedFilter) => {
        return savedFilter.title === title;
      }
    );

    if (!toBeDeletedFilter) {
      throw new Error('Error deleting saved filter.  Filter does not exist.');
    }

    if (toBeDeletedFilter.isWidget) {
      this.reindexWidgets();
    }

    this.userPreference.filters = this.userPreference.filters.filter(
      (savedFilter) => {
        return savedFilter.title !== title;
      }
    );

    return this.saveUserPreferenceChanges();
  }

  saveUserPreferenceChanges(): Observable<UserPreferenceResponse> {
    return this.httpClient
      .post<UserPreferenceResponse>(`${environment.apiBaseUrl}preference`, {
        preference: this.userPreference,
        lastCommitId: this.commitID
      })
      .pipe(
        retry(1),
        tap((response: UserPreferenceResponse) => {
          this.commitID = response.commitId;
        })
      );
  }

  addWidget(title: string): Observable<UserPreferenceResponse> {
    const added = this.userPreference.filters.find((f) => {
      return f.title === title && !f.isWidget;
    });
    if (!added) {
      throw new Error('Error adding widget');
    }

    added.isWidget = true;
    added.widgetIndex = this.getDashboardWidgets().length;

    return this.saveUserPreferenceChanges();
  }

  removeWidget(title: string): Observable<UserPreferenceResponse> {
    const removed = this.userPreference.filters.find((f) => {
      return f.title === title && f.isWidget;
    });

    if (!removed) {
      throw new Error('Error removing widget');
    }

    removed.isWidget = false;
    removed.widgetIndex = null;

    this.reindexWidgets();

    return this.saveUserPreferenceChanges();
  }

  saveFilter(savedFilter: SavedFilter): Observable<UserPreferenceResponse> {
    if (!this.userPreference.filters) {
      this.userPreference.filters = [];
    }
    const existingFilterIndex = this.userPreference.filters.findIndex((f) => {
      return f.title === savedFilter.title;
    });

    const savedFilterWithWidgetIndex = { ...savedFilter };

    // save as widget?
    if (savedFilter.isWidget) {
      savedFilterWithWidgetIndex.widgetIndex =
        existingFilterIndex >= 0
          ? this.userPreference.filters[existingFilterIndex].widgetIndex // keep prior widget index
          : this.getDashboardWidgets().length; // add to dashboard as last widget
    }

    // overwrite filter w/ matching title
    if (existingFilterIndex >= 0) {
      this.userPreference.filters[existingFilterIndex] =
        savedFilterWithWidgetIndex;
    } else {
      // add as new filter
      this.userPreference.filters = [
        ...this.userPreference.filters,
        savedFilterWithWidgetIndex
      ];
    }

    return this.saveUserPreferenceChanges();
  }

  reindexWidgets(): void {
    // all widgets w/ an index
    const enabledWidgets = this.userPreference.filters.filter((f) => {
      return typeof f.widgetIndex === 'number';
    });

    // reindex (eliminating any gaps)
    enabledWidgets.forEach((f, index) => {
      f.widgetIndex = index;
    });
  }

  renameFilter(oldTitle, newTitle): Observable<UserPreferenceResponse> {
    const filter = this.userPreference.filters.find((f) => {
      return f.title === oldTitle;
    });

    filter.title = newTitle;

    return this.saveUserPreferenceChanges();
  }
}
