/* eslint-disable no-plusplus */
/* eslint-disable curly */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ColorType } from '@gravity-angular/models';
import { AlertsService } from '@gravity-angular/base';
import { Observable, Subject, Subscription } from 'rxjs';
import { AmplifyService } from 'app/aws/amplify.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  subscription: Subscription;
  data: string;
  userStatus = new Subject<string>();
  utilitySelection = new Subject<boolean>();
  private readonly loadingState = new Subject<boolean>();
  constructor(
    private readonly alertsService: AlertsService,
    private readonly amplifyService: AmplifyService
  ) {}

  setLoadingState(value: boolean): void {
    this.loadingState.next(value);
  }

  getLoadingState(): Observable<boolean> {
    return this.loadingState.asObservable();
  }

  async getS3Csv(
    lambdaId: string,
    s3Path: string,
    bucket?: string
  ): Promise<any> {
    const s3Key = `${s3Path}/${lambdaId}.csv`;

    return this.amplifyService
      .getFileFromS3(s3Key, bucket)
      .then(file => {
        return file;
      })
      .catch(error => {
        console.error(error);
      });
  }

  async csvToJson(
    s3Key: string,
    s3Path: string,
    bucket?: string
  ): Promise<Object[]> {
    const file = await this.getS3Csv(s3Key, s3Path, bucket);
    const blob = await file.Body.text().then(textValue => {
      return textValue;
    });
    const data = blob.split('\n');
    const headers = data[0].split('|');

    const tableData = [];
    for (let i = 1; i < data.length; i++) {
      const obj = {};
      const row = data[i].split('|');
      for (let j = 0; j < headers.length; j++) {
        if (headers[j]) {
          obj[headers[j]] = row[j];
        }
      }
      if (Object.keys(obj).length > 0) {
        tableData.push(obj);
      }
    }

    return tableData;
  }

  async csvToObject<T>(s3Key: string, s3Path: string): Promise<T[]> {
    // Get the contents of the S3 file
    const file = await this.getS3Csv(s3Key, s3Path);
    const blob = await file.Body.text().then(textValue => {
      return textValue;
    });

    // Extract the headers and rows
    const data: string[] = blob.split('\n');
    const headers: string[] = data[0].split('|');
    const rows: string[] = data.slice(1);

    // For each data row, generate the appropriate object type
    const tableData: T[] = [];
    for (const row of rows) {
      const obj = {} as T;
      const rowData = row.split('|');

      for (const [index, header] of headers.entries()) {
        try {
          obj[header] = JSON.parse(rowData[index]);
        } catch {
          obj[header] = rowData[index];
        }
      }
      tableData.push(obj);
    }

    return tableData;
  }

  /**
   * Creates alert using gravity angular service, neccesary for lazy loaded modules.
   *
   * @param colorType ColorType provided by gravity angular model
   * @param alertTitle title of alert message
   * @param alertMessage message for alert
   * @param dismissable can alert be closed
   */
  createAlert(
    colorType: ColorType,
    alertTitle: string,
    alertMessage: string,
    dismissable: boolean
  ) {
    this.alertsService.addAlert({
      type: colorType,
      title: alertTitle,
      message: alertMessage,
      dismissable
    });
  }

  updatedUserStatus(status: string): void {
    this.userStatus.next(status);
  }
}
