import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class XgsGuardGuard implements CanActivate, CanLoad {
  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      take(1),
      map(({ isAuthenticated }) => {
        sessionStorage.setItem('route', state.url);
        if (!isAuthenticated) {
          this.router.navigate(['/authorize']);
        }

        return isAuthenticated;
      })
    );
  }

  // Checks if user is authenitcated before
  // allowing loading of lazy loaded modules
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (
      this.oidcSecurityService.getAccessToken() &&
      this.oidcSecurityService.getRefreshToken() &&
      this.oidcSecurityService.getAuthenticationResult()
    ) {
      return true;
    } else {

      window.location.href = `${environment.xgsBaseURL}/sso/logout`;
    }
  }
}

// Declare type for canDeactivate
type CanDeactivateType =
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree;

export interface CanComponentDeactivate {
  canDeactivate: () => CanDeactivateType;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  // Guard used to remove preferences from session storage when leaving a module
  // currently removes all but can be customized with the data parameter from app routing
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    sessionStorage.removeItem(currentRoute.routeConfig.path);

    return true;
  }
}
