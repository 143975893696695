import { Component, OnInit } from '@angular/core';
import { UploadService } from './services/upload.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-newpiperdiver',
  templateUrl: './newpiperdiver.component.html',
  styleUrls: ['./newpiperdiver.component.css']
})
export class NewpiperdiverComponent implements OnInit {
  uploadMessage: string = '';
  selectedFiles: FileList;
  constructor(private uploadService: UploadService) {}
  ngOnInit(): void {}

  async upload() {
    console.log(environment);
    console.log('Upload File');
    const file = this.selectedFiles.item(0);
    this.uploadService
      .uploadFile(file)
      .then(data => {
        console.log('File uploaded susccessfully', data);
        this.uploadMessage = 'File uploaded susccessfully!';
      })
      .catch(error => {
        console.error('Error uploading file', error);
        this.uploadMessage = 'Error on uploading file.';
      });
  }

  selectFile(event: any) {
    this.selectedFiles = event.target.files;
  }
}
