<mat-tab-group>
  <mat-tab label="Upload File">
    <br />
    <br />
    <div class="content">
      <input (change)="selectFile($event)" type="file" />
      <br />
      <br />
      <button (click)="upload()">Send file</button>
      <br />
      <div>{{ uploadMessage }}</div>
    </div>
  </mat-tab>

  <mat-tab label="Noise Detection">
    <p>To be implemented</p>
  </mat-tab>

  <mat-tab label="GAF Image Generation for Defect/Joints">
    <p>To be implemented</p>
  </mat-tab>

  <mat-tab label="Defect Prediction">
    <p>To be implemented</p>
  </mat-tab>

  <mat-tab label="Joint Prediction">
    <p>To be implemented</p>
  </mat-tab>

  <mat-tab label="Help">
    <p>To be implemented</p>
  </mat-tab>
</mat-tab-group>
