<div class="container">
  <div class="carousel">
    <ul class="list-ul">
      <li class="list-li" *ngFor="let slide of slides; let i = index">
        <ng-container *ngIf="i===currentIndex">
          <div class="carousel-slide" [@carouselAnimations]>
            <div class="carousel-text">
              <h1 class="overview">{{slide.header}}</h1>
              <p class="description">{{slide.description}}</p>
              <div class="link-button" *ngIf="slide.url != null">
                <a class="carousel-link" (click)="learnMore(slide.url)">Learn More</a>
              </div>
            </div>
            <div class="carousel-right">
              <img class="carousel-image" *ngIf="slide.src != null" [src]="slide.src" loading="lazy">
            </div>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>
  <div class="button-container">
    <div class="carousel-buttons">
      <button class="carousel-button"
        [ngStyle]="{'opacity': currentIndex===0? 1.0 : 0.5}"
        (click)= "first()">
      </button>
      <button class="carousel-button"
        [ngStyle]="{'opacity': currentIndex===1? 1.0 : 0.5}"
        (click)= "second()">
      </button>
      <button class="carousel-button"
        [ngStyle]="{'opacity': currentIndex===2? 1.0 : 0.5}"
        (click)= "third()">
      </button>
    </div>
  </div>
</div>
