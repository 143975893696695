import { Injectable } from '@angular/core';
import { interval, NEVER, Observable, Subject } from 'rxjs';
import { map, scan, startWith, switchMap } from 'rxjs/operators';

export interface ICounter {
  pause?: boolean;
  counterValue?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CarouselTimerService {
  private readonly counterSubject: Subject<ICounter> = new Subject();

  constructor() {}

  initInterval(duration: number): Observable<ICounter> {
    return this.counterSubject.pipe(
      startWith({ pause: false, counterValue: 0 }),
      scan((acc, val) => {
        return { ...acc, ...val };
      }),
      switchMap((state) => {
        return state.pause
          ? NEVER
          : interval(duration).pipe(
              map(() => {
                state.counterValue++;

                return state;
              })
            );
      })
    );
  }

  pauseCounter(): void {
    this.counterSubject.next({ pause: true });
  }

  resumeCounter(): void {
    this.counterSubject.next({ pause: false });
  }
}
