import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';
import {
  XGSCustomer,
  XGSRole,
  XGSUser
} from '@common/models/user-management.model';

@Injectable({
  providedIn: 'root'
})
export class XgsUmService {
  xgsBaseURL = environment.xgsBaseURL;
  xgsActiveUser: XGSUser;
  ddbUsers: Object;
  xgsUsers = new BehaviorSubject([] as XGSUser[]);
  xgsCustomers = [] as XGSCustomer[];
  xgsRoles = [] as XGSRole[];
  activeXgsUserRoles = [] as XGSRole[];
  userList = [] as XGSUser[];

  xgsRequirements = {
    um_base_url: environment.xgsBaseURL
  };

  httpHeader = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
    observe: 'response'
  };

  constructor(
    private readonly http: HttpClient,
    private readonly oidcSecurityService: OidcSecurityService
  ) {}

  getXgsUser(): XGSUser {
    return this.xgsActiveUser;
  }

  setUser(userData: XGSUser) {
    this.xgsActiveUser = userData;
    this.xgsActiveUser.roles = this.xgsActiveUser.roles[environment.xgsClientID]
      ? this.xgsActiveUser.roles[environment.xgsClientID]
      : this.xgsActiveUser.roles;
  }

  getUsers(): Observable<any> {
    // console.log(this.oidcSecurityService.getAccessToken());
    return this.http.get(
      `${this.xgsRequirements.um_base_url}/v1/users?size=10000`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  getUserByEmail(email: string): Observable<any> {
    return this.http.get(
      `${this.xgsRequirements.um_base_url}/v1/users/byidoremail/${email}`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  getUserDetail(userId: string): Observable<any> {
    return this.http.get(
      `${this.xgsRequirements.um_base_url}/v1/users/${userId}`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  editUser(user: object): Observable<any> {
    return this.http.patch(
      `${this.xgsRequirements.um_base_url}/v1/users/${user['id']}`,
      user,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  patchUserRoles(roleItems: object, user: object): Observable<any> {
    return this.http.patch(
      `${this.xgsRequirements.um_base_url}/v1/users/${user['id']}/roles`,
      roleItems,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  deleteAdminClaims(userId: string): Observable<any> {
    return this.http.delete(
      `${this.xgsRequirements.um_base_url}/v1/users/${userId}/adminClaims`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  deleteUser(user: object): Observable<any> {
    console.log(user);

    return this.http.delete(
      `${this.xgsRequirements.um_base_url}/v1/users/${user['id']}`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  getUserRoles(userId: string): Observable<any> {
    return this.http.get(
      `${this.xgsRequirements.um_base_url}/v1/users/${userId}/roles`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  getCustomers(): Observable<any> {
    console.log(this.oidcSecurityService.getAccessToken());

    return this.http.get(`${this.xgsRequirements.um_base_url}/v1/customers`, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
        observe: 'response'
      }
    });
  }

  getRoles(): Observable<any> {
    return this.http.get(`${this.xgsRequirements.um_base_url}/v1/roles`, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
        observe: 'response'
      }
    });
  }

  getRoleDetail(roleId: string): Observable<any> {
    return this.http.get(
      `${this.xgsRequirements.um_base_url}/v1/roles/${roleId}`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  createRole(role: object): Observable<any> {
    return this.http.post(
      `${this.xgsRequirements.um_base_url}/v1/roles`,
      role,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  createUser(user: object): Observable<any> {
    return this.http.post(
      `${this.xgsRequirements.um_base_url}/v1/users`,
      user,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  attachIAMIdentity(response: object, userId: string): Observable<any> {
    const adminClaim = {
      roleArn: response['roleArn'],
      DbGroups: response['customers']
    };

    return this.http.put(
      `${this.xgsRequirements.um_base_url}/v1/users/${userId}/adminClaims`,
      adminClaim,
      { headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
        observe: 'response'
      } }
    );
  }

  deleteRole(role: object): Observable<any> {
    return this.http.delete(
      `${this.xgsRequirements.um_base_url}/v1/roles/${role['id']}`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        }
      }
    );
  }

  sendVerificationEmail(userId: object): Observable<any> {
    return this.http.post(
      `${this.xgsRequirements.um_base_url}/v1/users/${userId}/verifyemail`,userId,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.oidcSecurityService.getAccessToken()}`,
          observe: 'response'
        },
        responseType: 'text'
      },
    );
  }
}
