import { Pipe, PipeTransform } from '@angular/core';
import { Schedule, ScheduleExecution } from '@common/models/scheduler-models';
import { TaskComponent } from '@common/models/tasks.model';
import moment from 'moment-timezone';
@Pipe({
  name: 'timezoneConversion'
})
export class TimezoneConversionPipe implements PipeTransform {
  /**
   * Will format time based on XGS Customer timezone if present
   *
   * @param time UTC time string
   * @param customer XGS Customer
   * @param quotes if string is surrounded by quotes
   * @returns UTC time if no timezone available otherwise converts
   */
  transform(time: string, quotes?: boolean): string {
    if (!time) {
      return;
    } else if (quotes) {
      // eslint-disable-next-line no-param-reassign
      time = this.formatTime(time);
    }

    const momentTime = moment(time);

    return momentTime.format('MM/DD/YYYY - h:mm:ss A');
  }

  /**
   * Formats time for GUI
   *
   * @param time time to format
   * @returns time without quotes
   */
  formatTime(time: string): string {
    return time.replace(/['"]+/g, '');
  }
}

@Pipe({
  name: 'abbrevateZone'
})
export class AbbrevateTimeZonePipe implements PipeTransform {
  transform(): string {
    const zone = moment.tz.guess();

    return moment.tz(zone).zoneAbbr();
  }
}

@Pipe({
  name: 'userAudit'
})
export class UserAuditImagePipe implements PipeTransform {
  /**
   * Formats inital and altered value of user audit trail for GUI
   *
   * @param auditValue inital or altered value from audit trail
   * @returns formatted string for GUI
   */
  transform(auditValue: string): string {
    const jsonValue = JSON.parse(auditValue);
    const entry = Object.entries(jsonValue);
    let temp = '';
    for (const [key, value] of entry) {
      if (entry.length > 3 && key === 'roles') {
        continue;
      } else if (key === 'roles') {
        for (const [key2, value2] of Object.entries(value)) {
          temp = `${temp}${key2}\n`;
        }
      } else {
        temp = `${temp}${key}: ${value}\n`;
      }
    }
    if (temp.length === 0) {
      temp = 'No Value';
    }

    return temp;
  }
}
@Pipe({
  name: 'roleAudit'
})
export class RoleAuditImagePipe implements PipeTransform {
  /**
   * Formats inital and altered value of role audit trail for GUI
   *
   * @param auditValue inital or altered value from audit trail
   * @returns formatted string for GUI
   */
  transform(auditValue: string): string {
    const jsonValue = JSON.parse(auditValue);
    const entry = Object.entries(jsonValue);
    let temp = '';
    for (const [key, value] of entry) {
      if (key === 'id') {
        continue;
      } else {
        temp = `${temp}${key}: ${value}\n`;
      }
    }
    if (temp.length === 0) {
      temp = 'No Value';
    }

    return temp;
  }
}

@Pipe({
  name: 'roleName'
})
export class RoleNamePipe implements PipeTransform {
  // Due to lack of customer scoping of roles
  // role names are formated with Customer Id and need
  // to be removed before loading to HTML
  // ***Potential can be removed as no custom roles have been created and scoping has been added needs validation before removing*** //
  transform(role: string): string {
    const name = role.substring(0, role.indexOf(' ->'));
    if (name.length > 0) {
      return name;
    }

    return role;
  }
}

@Pipe({
  name: 'scheduleImage'
})
export class ScheduleImagePipe implements PipeTransform {
  /**
   * Formats both old and new image for UI
   *
   * @param image image to be formated for UI
   */
  transform(image: string): string {
    if (!image) {
      return '';
    }
    const formattedFields: string[] = [];

    const schedule = JSON.parse(image) as Schedule;
    if (!schedule?.task) {
      return '';
    }

    for (const [key, value] of Object.entries(schedule.task)) {
      if (key === 'components') {
        for (const component of value as TaskComponent[]) {
          formattedFields.push(`${component.type}:`);
          for (const [key2, value2] of Object.entries(component.data)) {
            formattedFields.push(`   ${key2}: ${JSON.stringify(value2)}`);
          }
        }
      } else {
        formattedFields.push(`${key}: ${value}`);
      }
    }

    return formattedFields.length
      ? formattedFields.join('\n')
      : 'Empty or No Change';
  }
}

@Pipe({
  name: 'scheduleNames'
})
export class ScheduleNamesPipe implements PipeTransform {
  /**
   * Formats list of all selected schedules for the UI
   *
   * @param schedules list of schedules
   * @returns formated string of schedules names selected
   */
  transform(schedules: Schedule[]): string {
    const selectedSchedules = schedules?.filter(schedule => {
      return schedule.selected;
    });

    console.log(selectedSchedules);

    return selectedSchedules
      .map(a => {
        return a.name;
      })
      .join(',\n\n');
  }
}

@Pipe({
  name: 'executionReports'
})
export class ExecutionReportsPipe implements PipeTransform {
  /**
   * Formats Reports for UI
   *
   * @param execution execution log to format
   */
  transform(execution: ScheduleExecution): string {
    if (!execution.config) {
      return '';
    }
    try {
      return execution.config.name;
    } catch (error) {
      console.error(error);

      return '';
    }
  }
}
