<xg-header [appName]="appName" [showSearch]="showSearch" (toggleSideNav)="toggleSideNav($event)">
  <ng-container brandLogo>
    <img
      src="assets/app_brand_logo.png"
      alt="appName"
      class="brand-icon"
    />
  </ng-container>
  <span headerLeftOptionalContent class="header-item">
    <xg-commodity-switcher
      [commodityOptions]="commodityOptions"
      [utilityOptions]="utilityOptions"
    ></xg-commodity-switcher>
  </span>

  <ng-container headerRightOptionalContent>
    <a mat-menu-item routerLink="/landing">
      <mat-icon>home</mat-icon>
    </a>
  </ng-container>

  <ng-container initial>{{userData.givenName.charAt(0)}}</ng-container>
  <ng-container userMenu>
    <div disabled="disabled" mat-menu-item>
      <mat-icon>store</mat-icon>
      {{utilityOptions[0].value}}
    </div>
    <div disabled="disabled" mat-menu-item>
      <mat-icon>person</mat-icon>
      {{userData.givenName}} {{userData.familyName}}
    </div>
    <div disabled="disabled" mat-menu-item>
      <mat-icon>email</mat-icon>
      <span>{{userData.emailAddress}}</span>
    </div>
<!--    <div mat-menu-item (click)="openHelp()">-->
<!--      <mat-icon>help</mat-icon>-->
<!--      Help-->
<!--    </div>-->
<!--    <div mat-menu-item (click)="openAboutDialog()">-->
<!--      <mat-icon>info</mat-icon>-->
<!--      About-->
<!--    </div>-->
    <ng-content select="[headerOptionalMenuItem]"></ng-content>
    <a mat-menu-item id="Route-Logout" (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
      Logout
    </a>
  </ng-container>
</xg-header>
<div class="alert-container" [cdkPortalOutlet]="alerts"></div>
<xg-sidenav [navContent]="sideNavContent" (toggleChanged)="toggleSideNav($event)">
</xg-sidenav>