import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private readonly router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // eslint-disable-next-line no-console
    console.log('intercepted!', request);

    // eslint-disable-next-line no-param-reassign
    request = request.clone({
      headers: request.headers.set(
        'cookie',
        '[ { "key": "Cookie", "value": idToken_goes_here'
      )
    });

    return next.handle(request);
  }
}
