import { NgModule } from '@angular/core';
import { DateConversionsService } from './date-conversions/date-conversions.service';
import { AuthInterceptorService } from './http-interceptors/auth-interceptor.service';
import { IframeComponent } from './iframe/iframe.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { BaseModule, MaterialModule } from '@gravity-angular/base';
import { ComponentsModule } from '@gravity-angular/components';
import { LayoutModule } from '@gravity-angular/layout';
import { PagesModule } from '@gravity-angular/pages';
import {
  AbbrevateTimeZonePipe,
  RoleAuditImagePipe,
  RoleNamePipe,
  TimezoneConversionPipe,
  UserAuditImagePipe
} from './pipes/shared.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DropdownModule } from 'primeng/dropdown';

/**
 * @param http
 */
export const HttpLoaderFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PagesModule,
    ComponentsModule,
    MatSortModule,
    FlexLayoutModule,
    BaseModule,
    FormsModule,
    MatTableExporterModule,
    DropdownModule,
    LayoutModule,
    MaterialModule,
    MatDatepickerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [DateConversionsService, AuthInterceptorService],
  declarations: [
    TimezoneConversionPipe,
    UserAuditImagePipe,
    RoleAuditImagePipe,
    RoleNamePipe,
    AbbrevateTimeZonePipe,
    IframeComponent
  ],
  exports: [
    CommonModule,
    BaseModule,
    FormsModule,
    PagesModule,
    MatSortModule,
    ComponentsModule,
    FlexLayoutModule,
    LayoutModule,
    ReactiveFormsModule,
    MaterialModule,
    TimezoneConversionPipe,
    UserAuditImagePipe,
    RoleAuditImagePipe,
    RoleNamePipe,
    AbbrevateTimeZonePipe,
    TranslateModule,
    MatTableExporterModule,
    DropdownModule,
    IframeComponent
  ]
})
export class SharedModule {}

export { DateConversionsService, AuthInterceptorService };
