import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '@env/environment';
import { OidcStorageService } from './oidc-storage.service';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        storage: new OidcStorageService(),
        authority: `https://${environment.xgsIdp}`,
        redirectUrl: `${window.location.origin}/authorize`,
        postLogoutRedirectUri: `${environment.xgsBaseURL}/sso/logout`,
        unauthorizedRoute: '/login',
        clientId: environment.xgsClientID,
        scope: 'openid email profile roles',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewUserInfoAfterTokenRenew: true,
        ignoreNonceAfterRefresh: true,
        logLevel: environment.logLevel,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        maxIdTokenIatOffsetAllowedInSeconds: 60,
        historyCleanupOff: false
      }
    })
  ],
  exports: [AuthModule]
})
export class AuthConfigModule {}
