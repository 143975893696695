import { Component, OnInit } from '@angular/core';
import { AmplifyService } from '../aws/amplify.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  emailLink =
    'mailto:xylem-data-lake@xylem.com?Subject=Xylem Sprout: Issues and Questions&body=%0D%0Dhttps://www.xylemsprout.com';

  private readonly userData;
  private readonly amplifyService: AmplifyService;

  constructor(amplifyService: AmplifyService, appComponent: AppComponent) {
    this.amplifyService = amplifyService;
    this.userData = appComponent.getUserData();
  }

  ngOnInit(): void {
  }
}
