/* eslint-disable camelcase */
/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class OidcStorageService implements AbstractSecurityStorage {
  sproutKey = CryptoJS.enc.Hex.parse('XylemSproutXDLsXGSUMkeyusersdata');
  sproutIv = CryptoJS.enc.Hex.parse('atadsresuyekMUSGXsLDXtourpSmelyX');
  constructor() {}

  /**
   * Reads local storage values to be used for authentication and token exchange
   * converting local storage values to 
   * a string to be read by library
   *
   * @param key configID for OIDC flow
   * @returns config data/tokens for OIDC flow if found or null
   */
  read(key: string): string {
    const jsonValue = JSON.parse(localStorage.getItem(key));
    if (jsonValue) {
      const properties = Object.keys(jsonValue);
      for (const prop of properties) {
        if (prop === 'authnResult') {
          for (const result in jsonValue[prop]) {
            if (jsonValue[prop][result] && result.includes('token')) {
              jsonValue[prop][result] = this.deformatData(
                jsonValue[prop][result]
              );
            }
          }
        } else if (prop === 'userData' || prop === 'authzData') {
          jsonValue[prop] = JSON.parse(this.deformatData(jsonValue[prop]));
        }
      }

      return JSON.stringify(jsonValue);
    }

    return null;
  }

  /**
   * Used to create/update locale storage values.
   *
   * @param key configID for OIDC flow
   * @param value value for configID (userdata, tokens, etc.)
   */
  write(key: string, value: string): void {
    const jsonValue = JSON.parse(value);
    const properties = Object.keys(jsonValue);
    for (const prop of properties) {
      if (prop === 'authnResult') {
        for (const result in jsonValue[prop]) {
          if (jsonValue[prop][result] && result.includes('token')) {
            jsonValue[prop][result] = this.formatData(jsonValue[prop][result]);
          }
        }
      } else if (prop === 'userData' || prop === 'authzData') {
        jsonValue[prop] = this.formatData(JSON.stringify(jsonValue[prop]));
      }
    }
    localStorage.setItem(key, JSON.stringify(jsonValue));
  }

  /**
   * Encrypts data prior to storing in local storage
   * 
   * @param data string from OIDC flow
   * @returns encrypted string format of data
   */
  formatData(data: string): string {
    return CryptoJS.AES.encrypt(data, this.sproutKey, {
      iv: this.sproutIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  /**
   * Decrypts data from local storage to be read in application
   * 
   * @param data encrypted string
   * @returns decrypted string fromat of data
   */
  deformatData(data: string): string {
    const tempValue = CryptoJS.AES.decrypt(data, this.sproutKey, {
      iv: this.sproutIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return tempValue.toString(CryptoJS.enc.Utf8);
  }

  /**
   * Removes local storage data
   *
   * @param key configID of OIDC flow
   */
  remove(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Removes all localStorage values
   */
  clear(): void {
    localStorage.clear();
  }
}
