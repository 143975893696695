import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { OidcStorageService } from 'app/auth/oidc-config/oidc-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly oidcStorageService: OidcStorageService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.userAuthenticated();
  }

  /**
   * Uses angular-auth-oidc-client to see if user has been authorized. 
   * If not route to XGS SSO login screen and if so to a previous route 
   * if applicable or landing page.
   */
   userAuthenticated(): void {
    this.oidcSecurityService.checkAuth().subscribe(async auth => {
      if (!auth.isAuthenticated) {
        this.ssoLogin();
      } else {
        return sessionStorage.getItem('route')
          ? this.router.navigate([sessionStorage.getItem('route')])
          : this.router.navigate(['/landing']);
      }
    });
  }

  /**
   * Routes user to XGS SSO Login screen building 
   * redirect url and ensuring it is correct
   */
   ssoLogin(): void {
    this.oidcStorageService.clear();
    localStorage.setItem('state', 'validating');
    const url = new URL(window.location.origin);
    const loc = url.href;
    if (
      environment.production &&
      (loc.startsWith(`https://${environment.domain}/`) ||
        loc.startsWith(`https://www.${environment.domain}/`))
    ) {
      window.location.assign(
        `${environment.xgsBaseURL}/sso?redirect=${loc}authorize`
      );
    } else if (
      !environment.production &&
      (loc.startsWith(`https://${environment.domain}/`) ||
        loc.startsWith(`https://www.${environment.domain}/`) ||
        loc.startsWith('http://localhost:4200/'))
    ) {
      window.location.assign(
        `${environment.xgsBaseURL}/sso?redirect=${loc}authorize`
      );
    }
  }
}
