import { Injectable } from '@angular/core';
import S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})


export class UploadService {
  private s3: S3;

  constructor(){
    this.s3 = new S3({
      accessKeyId: 'AKIARJWMHVLUGCWHUINW',
      secretAccessKey: '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',
      region: 'us-east-1'
  });
  }

 uploadFile(file: File): Promise<S3.ManagedUpload.SendData>{
  const params: S3.PutObjectRequest = {
    Bucket: 'xds-pipervisionmocapp',
    Key: 'angular-app/input/' + file.name,
    Body: file,
    ContentLength: file.size
  };
  return this.s3.upload(params).promise()
 }
}


