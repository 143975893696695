<div class="idle-dialog">
    <div class="header-bar">
        <h2 mat-dialog-title><mat-icon class="warning">warning</mat-icon>Idle Timeout</h2>
    </div>
    <div mat-dialog-content class="info">
        <p>You have been idle for awhile and for security reasons your session will end in 2 minutes.</p>
        <p>Would you like to continue your current session?</p>
    </div>
    <div mat-dialog-actions>
        <br>
        <div class="button-container">
            <button class="resume-session-button" mat-button mat-dialog-close="resume">Continue</button>
        </div>
    </div>
</div>