import { environment } from '@env/environment';

import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit
} from '@angular/core';

import { AlertsComponent } from '@gravity-angular/base';
import {
  LoadingService,
  ResponsiveService,
  SideNavModel
} from '@gravity-angular/layout';
import { PreviousRouteService } from '@gravity-angular/utils';
import {
  AuthOptions,
  EventTypes,
  OidcSecurityService,
  PublicEventsService
} from 'angular-auth-oidc-client';
import { first, filter } from 'rxjs/operators';
import { AmplifyService } from './aws/amplify.service';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { IdleLogoutDialogComponent } from './idle-logout-dialog/idle-logout-dialog.component';
import { OidcStorageService } from './auth/oidc-config/oidc-storage.service';
import { Router } from '@angular/router';
import { NotificationsService } from '@common/notifications/notifications.service';
import { XgsUmService } from './auth/xgs-service/xgs-um.service';
import { XGSRole } from '@common/models/user-management.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  sideNavContent: SideNavModel;
  appName: string;
  alerts: ComponentPortal<AlertsComponent>;
  showMainLayout: boolean;
  showSearch: boolean;
  home;
  commodityOptions;
  utilityOptions;
  keycloakUserData;
  userData;
  idleState = 'NOT_STARTED';
  countdown?: number = null;
  session = false;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly previousRouteService: PreviousRouteService,
    private readonly oidcStorageService: OidcStorageService,
    private readonly eventService: PublicEventsService,
    private readonly router: Router,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly amplifyService: AmplifyService,
    private readonly cookieService: CookieService,
    private readonly notificationsService: NotificationsService,
    private readonly loadingService: LoadingService,
    private readonly xgsUmService: XgsUmService,
    public dialog: MatDialog,
    private readonly idle: Idle,
    cd: ChangeDetectorRef
  ) {
    this.previousRouteService.initialize();
    this.showMainLayout = true;
    this.showSearch = true;

    this.commodityOptions = [
      {
        name: 'Data',
        value: 'DATA',
        icon: 'analytics'
      }
    ];

    this.utilityOptions = [
      {
        name: ' ',
        value: ' '
      }
    ];

    this.userData = {};

    // listen for the user data changed event then update user data
    // and call the signIn function in the amplify service
    this.eventService
      .registerForEvents()
      .pipe(
        first(notification => {
          return notification.type === EventTypes.UserDataChanged;
        })
      )
      .subscribe(() => {
        this.keycloakUserData = this.oidcSecurityService.getUserData()
          ? this.oidcSecurityService.getUserData()
          : {};
        const roles = Object.keys(this.keycloakUserData.roles);

        this.userData.givenName = this.keycloakUserData.given_name
          ? this.keycloakUserData.given_name
          : ' ';
        this.userData.familyName = this.keycloakUserData.family_name
          ? this.keycloakUserData.family_name
          : ' ';
        localStorage.setItem(
          'user',
          `${this.userData.givenName} ${this.userData.familyName}`
        );
        this.userData.emailAddress = this.keycloakUserData.email;
        this.userData.id = this.keycloakUserData.sub;
        this.userData.roles = this.keycloakUserData.roles;
        this.accessIperl();
        this.accessDt96();
        this.accessInventory();
        this.utilityOptions[0].name = this.keycloakUserData.roles[roles[0]];
        this.utilityOptions[0].value = this.keycloakUserData.roles[roles[0]];

        // If user has SupersetAdmin role, expose the route in side nav
        if (
          roles.some(role => {
            return role.toLowerCase().includes('superset');
          })
        ) {
          this.sideNavContent.routes.push({
            route: 'superset',
            display: 'Apache Superset',
            icon: 'all_inclusive',
            id: 'supersetDashboardLink'
          });
        }

        this.accessKepler();
        this.accessPipediver();
        this.accessLifeCycleServices();
        this.accessRepairEconomy();
        this.accessPipelineData();
        this.accessGSODashboard();
        this.accessAllUsers();
        this.addSchedulerRoute();
        this.accessPiperDiver();
        const routes = new Set(this.sideNavContent.routes);
        this.sideNavContent.routes = Array.from(routes);
        this.amplifyService.signIn();
        cd.detectChanges();
      });

    // Listen for NewAuthenticationResult and store the new access token
    // as cookie for subdomains (i.e. superset) to use.
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => {
          return notification.type === EventTypes.NewAuthenticationResult;
        })
      )
      .subscribe(() => {
        const accessToken = this.oidcSecurityService.getAccessToken();
        // eslint-disable-next-line dot-notation
        const expirationDate = new Date(jwt_decode(accessToken)['exp'] * 1000);

        this.cookieService.set(
          `${environment.environmentName}_xdl_xgs_access_token`,
          accessToken,
          expirationDate,
          '/',
          environment.domain,
          true,
          'None'
        );
      });

    idle.setIdle(1680); // time in seconds (13 mins) before user is notified of idle state
    idle.setTimeout(120); // time in seconds (2 mins) folowing notification before user is logged off
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // When user is idle dialog will open to warn user
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
      const dialogRef = this.dialog.open(IdleLogoutDialogComponent, {
        width: '550px'
      });
      dialogRef.afterClosed();
    });

    // Subscribes to detect when user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      this.countdown = null;
      cd.detectChanges();
    });

    // Subscribes to detect when a users session is timing out
    idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.logout();
    });

    this.notificationsService.getLoadingState().subscribe(value => {
      this.loadingService.showLoading(value);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.responsiveService.checkWidth();
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.hidden && localStorage.getItem('state') === 'invalid') {
      this.oidcStorageService.clear();
    }
  }

  ngOnInit(): void {
    this.reset();
    this.appName = environment.appName;

    this.home = {
      location: '/',
      text: 'Home'
    };

    this.alerts = new ComponentPortal(AlertsComponent);
    this.sideNavContent = {
      mode: 'side',
      opened: false,
      routes: [
        {
          route: 'user/user-detail',
          display: 'User Profile',
          icon: 'account_box',
          id: 'userDetailLink'
        }
      ]
    };

    this.userData.givenName = ' ';
    this.userData.familyName = ' ';

    window.onstorage = () => {
      if (
        localStorage.getItem('state') === 'invalid' ||
        localStorage.getItem('state') === 'validating'
      ) {
        setTimeout(() => {
          sessionStorage.clear();
          this.router.navigate(['/login']);
        }, 4000);
      }
    };

    setTimeout(() => {
      this.session = true;
    }, 123000);
  }

  reset() {
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = null;
  }

  toggleSideNav(opened?: boolean): void {
    this.sideNavContent.opened =
      opened === undefined ? !this.sideNavContent.opened : opened;
  }

  /**
   * User is logged out and redirect url updated based on token lifecycle
   */
  async logout(): Promise<void> {
    sessionStorage.removeItem('route');
    localStorage.setItem('state', 'invalid');
    localStorage.removeItem('user');
    const idTokenHint = this.oidcSecurityService.getIdToken();
    if (this.session) {
      const customParams: AuthOptions = {
        urlHandler: url => {
          window.location.href = this.logoutURLHelper(url, idTokenHint);
        }
      };
      this.oidcSecurityService.logoff(undefined, customParams);
    } else {
      this.oidcSecurityService.logoff();
    }
  }

  /**
   * Helper function to edit logout url if needed
   * based on token lifecycle updating redirect uri.
   *
   * @param url url to be used for user logout
   * @param idTokenHint id_token captured before storage is cleared
   * @returns url to use for logout
   */
  logoutURLHelper(url: string, idTokenHint: string): string {
    if (url.includes('id_token_hint=undefined')) {
      if (idTokenHint) {
        url = url.replace(
          'id_token_hint=undefined',
          `id_token_hint=${idTokenHint}`
        );
      } else {
        this.oidcStorageService.clear();
        this.router.navigate(['/login']);
      }
    }
    this.oidcStorageService.clear();
    const origin = new URL(window.location.origin);
    const loc = origin.href;

    return loc.startsWith(`https://${environment.domain}/`) ||
      loc.startsWith(`https://www.${environment.domain}/`) ||
      (loc.startsWith('http://localhost:4200/') && !environment.production)
      ? url.replace(
          encodeURIComponent(`${environment.xgsBaseURL}/sso/logout`),
          encodeURIComponent(`${loc}/login`)
        )
      : url.replace(
          encodeURIComponent(`${environment.xgsBaseURL}/sso/logout`),
          encodeURIComponent(`${environment.domain}/login`)
        );
  }

  getUserData(): object {
    return this.userData;
  }

  accessAllUsers(): void {
    const accessUsersRoute = {
      route: 'user/user-list',
      display: 'Users List',
      icon: 'supervisor_account',
      id: 'userListLink'
    };
    const roleListAccess = {
      route: 'role/role-list',
      display: 'Roles List',
      icon: 'verified_user',
      id: 'roleListLink'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (role === 'DataLakeANDDataScienceTeam') {
        this.sideNavContent.routes.push(accessUsersRoute);
      }
      if (role === 'DataLakeANDDataScienceTeam') {
        this.sideNavContent.routes.push(roleListAccess);
      }
    }
  }

  accessIperl(): void {
    const Iperl = {
      route: 'iperl/iperloverview',
      display: 'IPerl EMEA Dashboard',
      icon: 'dashboard',
      id: 'iperldashboardLink'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (role === 'OXI:APPLICATIONADMIN' || role === 'ipearlEMEA') {
        this.sideNavContent.routes.push(Iperl);
      }
    }
  }

  accessDt96(): void {
    const Dt96 = {
      route: 'dt96dashboard',
      display: 'High Temperature Alarms Dashboard',
      icon: 'device_thermostat',
      id: 'dt96dashboardLink'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (role === 'OXI:APPLICATIONADMIN' || role === 'DT96StratusIQ') {
        this.sideNavContent.routes.push(Dt96);
      }
    }
  }

  accessInventory(): void {
    const Inventory = {
      route: 'data-entry/data-entrydashboard',
      display: 'Data Entry Dashboard',
      icon: 'table_view',
      id: 'dataentrydashboardLink'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (role === 'OXI:APPLICATIONADMIN') {
        this.sideNavContent.routes.push(Inventory);
      }
    }
  }

  accessKepler(): void {
    const kepler = {
      route: 'kepler',
      display: 'Kepler Dashboard',
      icon: 'map',
      id: 'kepler_dashboard'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (role === 'OXI:APPLICATIONADMIN') {
        this.sideNavContent.routes.push(kepler);
      }
    }
  }

  accessPipediver(): void {
    const pipediver = {
      route: 'pipediver-dashboard',
      display: 'Pipediver Dashboard',
      icon: 'track_changes',
      id: 'pipediver-dashboard'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (role === 'OXI:APPLICATIONADMIN' || role === 'PipeDiver') {
        this.sideNavContent.routes.push(pipediver);
      }
    }
  }

  accessRepairEconomy(): void {
    const repairEconomy = {
      route: 'repair-economy',
      display: 'Repair Economy Tool',
      icon: 'build',
      id: 'repair-economy-dashboard'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (role === 'OXI:APPLICATIONADMIN' || role === 'RepairEconomyTool') {
        this.sideNavContent.routes.push(repairEconomy);
      }
    }
  }

  accessLifeCycleServices(): void {
    const lifecycleService = {
      route: 'lifecycle-service',
      display: 'Lifecycle Services Tool',
      icon: 'tab',
      id: 'lifecycle-service'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (
        role === 'OXI:APPLICATIONADMIN' ||
        role === 'lifecycleservicestool' ||
        role === 'DataLakeANDDataScienceTeam'
      ) {
        this.sideNavContent.routes.push(lifecycleService);
      }
    }
  }

  accessPipelineData(): void {
    const pipelineData = {
      route: 'pipeline-data-table',
      display: 'Pipeline Data Table',
      icon: 'dataset',
      id: 'pipeline-data-table'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (role === 'OXI:APPLICATIONADMIN' || role === 'PipelineDataTable') {
        this.sideNavContent.routes.push(pipelineData);
      }
    }
  }

  accessGSODashboard(): void {
    const gsoDatawarehouse = {
      route: 'gsodatawarehouse',
      display: 'GSO Data Warehouse',
      icon: 'storage',
      id: 'gsodatawarehouse'
    };
    for (const role of Object.keys(this.userData.roles)) {
      if (
        role === 'OXI:APPLICATIONADMIN' ||
        role === 'DataLakeANDDataScienceTeam' ||
        role === 'SupersetGSODataWarehouse'
      ) {
        this.sideNavContent.routes.push(gsoDatawarehouse);
      }
    }
  }
  accessPiperDiver(): void {
    const piperDiver = {
      route: 'newpiperdiver/screen1',
      display: 'New Piper Diver',
      icon: 'account_box',
      id: 'helloWorldLink'
    };
    this.sideNavContent.routes.push(piperDiver);
  }

  async addSchedulerRoute(): Promise<void> {
    const schedulerRoute = {
      route: 'scheduler',
      display: 'Scheduler',
      icon: 'schedule',
      id: 'schedulerLink'
    };

    const roles = await this.getXgsRoles(this.userData.id);
    const hasAccess = roles?.some(role => {
      return (
        role.name === 'OXI:APPLICATIONADMIN' ||
        role.permissions.some(permission => {
          return permission.includes('scheduler');
        })
      );
    });
    if (!hasAccess) {
      return;
    }

    if (
      !this.sideNavContent.routes.some(route => {
        return route.route === 'scheduler';
      })
    ) {
      this.sideNavContent.routes.push(schedulerRoute);
    }
  }

  async getXgsRoles(userId): Promise<XGSRole[]> {
    return new Promise((resolve, reject) => {
      this.xgsUmService.getUserRoles(userId).subscribe(
        roles => {
          resolve(roles);
        },
        () => {
          reject([]);
        }
      );
    });
  }
}
