import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-idle-logout-dialog',
  templateUrl: './idle-logout-dialog.component.html',
  styleUrls: ['./idle-logout-dialog.component.scss']
})
export class IdleLogoutDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<IdleLogoutDialogComponent>) {}

  ngOnInit(): void {}
}
