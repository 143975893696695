/* eslint-disable complexity */
/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { XgsUmService } from '../xgs-service/xgs-um.service';
import { XGSRole } from '@common/models/user-management.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  customer: string;
  userRoles: object[];
  private readonly userData;
  constructor(
    private readonly router: Router,
    private readonly xgsUmService: XgsUmService,
    private readonly oidcSecurityService: OidcSecurityService
  ) {
    this.userData = this.oidcSecurityService.getUserData();
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.getPermissions().then(async roles => {
      // if the route is the scheduler, check if the user has any scheduler permission
      if (route.data.permission === 'scheduler') {
        return roles.some(role => {
          return role.permissions.some(perm => {
            return perm.includes('scheduler');
          });
        }) ||
          roles.some(role => {
            return role.name === 'OXI:APPLICATIONADMIN';
          })
          ? true
          : false;
      }

      // otherwise, check if the user has the permission for the route
      switch (route.routeConfig.path) {
        case 'superset':
          // eslint-disable-next-line prettier/prettier, arrow-body-style, dot-notation
          if (
            roles.some(role => role['name'].toLowerCase().includes('superset'))
          ) {
            return true;
          }
          break;

        case 'dt96dashboard':
          if (
            roles.some(role => {
              if (role['name'] === 'DT96StratusIQ') {
                return true;
              } else if (role['name'] === 'OXI:APPLICATIONADMIN') {
                return true;
              }
            })
          ) {
            return true;
          }
          break;

        case 'pipediver-dashboard':
          if (
            roles.some(role => {
              if (role['name'] === 'PipeDiver') {
                return true;
              } else if (role['name'] === 'OXI:APPLICATIONADMIN') {
                return true;
              }
            })
          ) {
            return true;
          }
          break;

        case 'pipeline-data-table':
          if (
            roles.some(role => {
              if (role['name'] === 'PipelineDataTable') {
                return true;
              } else if (role['name'] === 'OXI:APPLICATIONADMIN') {
                return true;
              }
            })
          ) {
            return true;
          }
          break;

        case 'iperloverview' ||
          'iperlhelp' ||
          'iperlblips' ||
          'iperlspc' ||
          'iperlmeter' ||
          'iperloverview' ||
          'iperlscatterplots':
          if (
            roles.some(role => {
              if (role['name'] === 'ipearlEMEA') {
                return true;
              } else if (role['name'] === 'OXI:APPLICATIONADMIN') {
                return true;
              }
            })
          ) {
            return true;
          }
          break;

        case 'data-entrydashboard' || 'view-edit-table':
          if (
            roles.some(role => {
              if (role['name'] === 'DataEntry') {
                return true;
              } else if (role['name'] === 'OXI:APPLICATIONADMIN') {
                return true;
              }
            })
          ) {
            return true;
          }
          break;

        case 'repair-economy':
          if (
            roles.some(role => {
              if (role['name'] === 'RepairEconomyTool') {
                return true;
              } else if (role['name'] === 'OXI:APPLICATIONADMIN') {
                return true;
              }
            })
          ) {
            return true;
          }
          break;

        default:
          return true;
      }

      return this.router.navigate(['/landing']);
    });
  }

  async getPermissions(): Promise<XGSRole[]> {
    return new Promise((resolve, reject) => {
      this.xgsUmService.getUserRoles(this.userData.sub).subscribe(
        roles => {
          resolve(roles);
        },
        () => {
          reject([]);
        }
      );
    });
  }
}
