<div class="landing">
  <app-carousel-slider></app-carousel-slider>
  <div class="intro-connect">
    <div class="introduction">
      <h1>Welcome to Xylem Sprout!</h1>
      <p>
        This application is a place for proofs of concept, support tooling and demonstration of Xylem innovation.
      </p>
      <p>
        Through this application we provide access into tooling that is for demonstration of potential upcoming solutions or offers support tools for existing solutions. 
      </p>
    </div>
    <div class="connect">
      <h1>Connect With Us</h1>
      <p>
        If you run into any issues or questions, please reach out to <a [href]="emailLink" target="_top">xylem-data-lake@xylem.com</a>.
      </p>
    </div>
  </div>
</div>
