import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {
  @Input() title: string = '';
  @Input() url: string = '';

  sanitizedUrl: SafeResourceUrl;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.updateDashboardUrl();
  }

  updateDashboardUrl(): void {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
