// import { SessionDataTypes } from '@common/enums/session-data-types';
import { AppLoadData } from '@common/models/session-data.model';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionInformationService, User } from '@gravity-angular/auth';
import { UserPreferenceService } from '@common/user-preference/user-preference.service';

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly sessionInfoService: SessionInformationService,
    private readonly userPrefService: UserPreferenceService
  ) {
    // eslint-disable-next-line no-console
    // console.log('aws amplify has been initialized...');
  }

  async getAppSettings(): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('in getAppSettings function...');

    // control what routes are shown in nav bar based on role

    return;

    // const [appLoadData] = await Promise.all([this.getAppLoadData()]);
    // const selectedUtility = appLoadData.utilities.find((u) => {
    //   return u.name === appLoadData.utility;
    // });
    // appLoadData.timezone = selectedUtility.timezone;
    //
    // this.sessionInfoService.setSessionData(
    //   SessionDataTypes.utilities,
    //   appLoadData.utilities
    // );
    //
    // this.sessionInfoService.setSessionData(
    //   SessionDataTypes.alarms,
    //   appLoadData.alarms
    // );
    //
    // this.sessionInfoService.setSessionData(
    //   SessionDataTypes.timezone,
    //   appLoadData.timezone
    // );
    //
    // this.sessionInfoService.setSessionData(
    //   SessionDataTypes.externalDomain,
    //   appLoadData.externalDomain
    // );
    //
    // this.sessionInfoService.setSessionData(
    //   SessionDataTypes.deviceCount,
    //   appLoadData.deviceCount
    // );
    //
    // this.sessionInfoService.setSessionData(
    //   SessionDataTypes.loggedOnUser,
    //   this.getUser(appLoadData.user)
    // );
    //
    // this.sessionInfoService.setSessionData(
    //   SessionDataTypes.utility,
    //   appLoadData.utility
    // );
    //
    // this.userPrefService.initUserPreference(
    //   appLoadData.userPreference.preference,
    //   appLoadData.userPreference.commitId
    // );
  }

  getUser(user): User {
    return {
      emailAddress: user.email,
      firstName: user.first,
      lastName: user.last,
      initial:
        user.first.charAt(0) || user.last.charAt(0) || user.email.charAt(0),
      nameString:
        user.last && user.first
          ? `${user.last}, ${user.first}`
          : user.first || user.last
    };
  }

  async getAppLoadData(): Promise<AppLoadData> {
    return this.httpClient
      .get<AppLoadData>(`${environment.apiBaseUrl}session`)
      .toPromise();
  }
}
