import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';

import { AuthConfig, AuthModule } from '@gravity-angular/auth';
import { ErrorConfig, ErrorModule } from '@gravity-angular/error';
import { UtilsModule } from '@gravity-angular/utils';

import { PortalModule } from '@angular/cdk/portal';
import { MatDialogModule } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { AppLoadService } from './app-load/app-load.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth/oidc-config/auth-config.module';
import { AmplifyService } from './aws/amplify.service';
import { CarouselSliderComponent } from './landing/carousel-slider/carousel-slider.component';
import { LandingComponent } from './landing/landing.component';
import { DateConversionsService, SharedModule } from './shared/shared.module';
import { ProcessingDialogComponent } from './user-management/processing-dialog/processing-dialog.component';

import { SupersetComponent } from './superset/superset.component';
import { NewpiperdiverComponent } from './newpiperdiver/newpiperdiver.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';

import { IdleLogoutDialogComponent } from './idle-logout-dialog/idle-logout-dialog.component';
import { NgIdleModule } from '@ng-idle/core';
import { AuthorizeComponent } from './authorize/authorize.component';
import { LoginComponent } from './login/login.component';

/**
 * @param appLoadService the app load service
 */
export const getAppSettings = (appLoadService: AppLoadService) => {
  return async (): Promise<any> => {
    return appLoadService.getAppSettings();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    CarouselSliderComponent,
    ProcessingDialogComponent,
    CarouselSliderComponent,
    CarouselSliderComponent,
    SupersetComponent,
    IdleLogoutDialogComponent,
    AuthorizeComponent,
    LoginComponent,
    NewpiperdiverComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule.forRoot({
      enableLocalhostRedirect: environment.localhostRedirect,
      production: environment.production,
      apiBaseUrl: environment.apiBaseUrl,
      request: environment.request
    } as AuthConfig),
    ErrorModule.forRoot({
      production: environment.production,
      enableLocalhostRedirect: environment.localhostRedirect
    } as ErrorConfig),
    NgIdleModule.forRoot(),
    PortalModule,
    UtilsModule,
    HttpClientModule,
    MatSliderModule,
    MatTableModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSliderModule,
    MatDialogModule,
    AuthConfigModule,
    SharedModule
  ],
  exports: [],
  providers: [
    DateConversionsService,
    AmplifyService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: getAppSettings,
      deps: [AppLoadService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
