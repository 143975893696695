import { Component, HostListener, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CarouselTimerService } from 'app/landing/carousel-slider/carousel-timer.service';

export interface carouselSlide {
  src: string;
  name: string;
  url: string;
  header: string;
  description: string;
}

@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: ['./carousel-slider.component.scss'],
  animations: [
    trigger('carouselAnimations', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('600ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('400ms ease-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class CarouselSliderComponent implements OnInit {
  slides: carouselSlide[] = [
    {
      src: 'assets/xylem_sprout.png',
      name: 'Intro',
      url: null,
      header: 'The Power of Data',
      description:
        'Xylem Sprout exists to display what is possible with data and Xylem innovation'
    },
    {
      src: 'assets/xylem_services.jpg',
      name: 'Xylem services placeholder found at https://www.xylem.com/en-us/careers/',
      url: 'https://sensus.com/services/',
      header:
        'Need Additional Help to determine what is actionable with your data?',
      description:
        'Xylem offers professional business process consulting, software development & data science services that can be tailored to your specific needs.'
    },
    // {
    //   src: 'assets/Pressure_Profile_Screen.png',
    //   name: 'Pressure Profile',
    //   url: 'https://sensus.com/products/pressure-profile',
    //   header: 'Pressure Profile',
    //   description:
    //     'Ensure Safe, Relible Water Service throughout your Distribution System. Learn about the latest offer in the Sensus Analytics portfolio of products'
    // },
    {
      src: 'assets/energy_insight_edit.png',
      name: 'Energy Insight',
      url: 'https://sensus.com/products/energy-insight/',
      header: 'Energy Insight',
      description:
        'Balance your energy purchases and sales to the electron and to the penny. Learn about the latest offer in the Sensus Analytics portfolio of products'
    }
  ];

  currentIndex: number;
  counterValue: string;
  time: number;
  interval: Subscription;

  constructor(private readonly carouselTimer: CarouselTimerService) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.carouselTimer.pauseCounter();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.carouselTimer.resumeCounter();
  }

  ngOnInit(): void {
    this.currentIndex = 0;
    this.time = 8;
    this.startTimer();
  }

  startTimer(): void {
    if (this.interval) {
      this.interval.unsubscribe();
    }
    this.interval = this.carouselTimer
      .initInterval(this.time * 10)
      .subscribe((d) => {
        this.counterValue = `${d.counterValue}%`;
        if (d.counterValue === 100) {
          if (this.currentIndex === 2) {
            this.first();
          } else {
            this.currentIndex++;
            this.startTimer();
          }
        }
      });
  }

  first(): void {
    this.currentIndex = 0;
    this.startTimer();
  }

  second(): void {
    this.currentIndex = 1;
    this.startTimer();
  }

  third(): void {
    this.currentIndex = 2;
    this.startTimer();
  }

  learnMore(url: string): void {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location.href = url;
  }
}
